<template>
  <single-page>
    <article v-if="lang == 'zh-CN'" lang="zh-CN">
      <h1>分享奖励提现教程</h1>
      <video src="https://video.meke.fun/Get_And_Swap_WMEKE_zh.mp4" 
        controls="controls" 
        height="1920"
        poster="https://video.meke.fun/Get_And_Swap_WMEKE_zh_poster.png"
        style="height:70vh; width:100%;object-fit:contain;"></video>
    </article>
    <article v-else lang="en-US">
      <h1>Share reward withdrawal tutorial</h1>
      <video src="https://video.meke.fun/Get_And_Swap_WMEKE_en.mp4" 
          controls="controls" 
          height="1920"
          poster="https://video.meke.fun/Get_And_Swap_WMEKE_en_poster.png"
          style="height:70vh;width:100%;object-fit:contain;"></video>
  </article>
</single-page></template>

<script>
import SinglePage from './components/singlePage'
export default {
  components: {
    SinglePage
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  }
};
</script>
<style lang="less" scoped></style>